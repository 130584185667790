import { stageParameter } from "@tc/app-parameter"
import {
  DeploymentStages,
  deploymentStage,
  isDeploymentStage,
  stage,
} from "@tc/env-stage"

enum DfeWebAppDomain {
  dev = "dev.tradecert.mpi.govt.nz",
  tst1 = "test1.tradecert.mpi.govt.nz",
  tst2 = "test2.tradecert.mpi.govt.nz",
  prd = "tradecert.mpi.govt.nz",
}

enum DfeB2CLogoutUrl {
  tst2 = "https://b2cst.b2clogin.com/b2cst.onmicrosoft.com/oauth2/v2.0/logout",
  prd = "https://b2cprd.b2clogin.com/b2cprd.onmicrosoft.com/oauth2/v2.0/logout",
}

//b2c policy name
const b2cPolicyName = "b2c_1a_mpi_010_rp_oidc"
const mpiWebsite = "https://www.mpi.govt.nz"

/**
 * Redshield domain name for current stage.
 */
export const redshieldDomain = (): string => {
  if (isDeploymentStage(stage)) {
    return DfeWebAppDomain[stage]
  } else {
    throw new Error(`Unrecognised stage ${stage}`)
  }
}

/**
 * Redshield base url for current stage.
 */
export const authBaseUrl = (): string => {
  if (isDeploymentStage(stage)) {
    return `https://${redshieldDomain()}`
  } else {
    throw new Error(`Unrecognised stage ${stage}`)
  }
}

/**
 * Cognito app client id.
 */
export const appClientId = async (): Promise<string> => {
  return stageParameter("cognito", "dfe-client-id")
}

/**
 * Cognito pool id.
 */
const cognitoPoolId = async (): Promise<string> => {
  return stageParameter("cognito", "pool-id")
}

export const issuer = async (): Promise<string> => {
  return `https://cognito-idp.ap-southeast-2.amazonaws.com/${await cognitoPoolId()}`
}

export const wellKnown = async () => {
  const url = await issuer()
  const response = await fetch(url + "/.well-known/openid-configuration")
  return response.json()
}

export const serverSideLogoutUrl = async () => {
  const wk = await wellKnown()
  let logoutUrl: string
  switch (deploymentStage()) {
    case DeploymentStages.TST2: {
      const b2cLogoutQuery = `p=${b2cPolicyName}&post_logout_redirect_uri=${mpiWebsite}`
      logoutUrl = `${DfeB2CLogoutUrl.tst2}?${b2cLogoutQuery}`
      break
    }
    case DeploymentStages.PRD: {
      const b2cLogoutQuery = `p=${b2cPolicyName}&post_logout_redirect_uri=${mpiWebsite}`
      logoutUrl = `${DfeB2CLogoutUrl.prd}?${b2cLogoutQuery}`
      break
    }
    default:
      logoutUrl = `${process.env.NEXTAUTH_URL || authBaseUrl()}/signout`
  }
  const query = new URLSearchParams({
    client_id: await appClientId(),
    logout_uri: logoutUrl,
  })
  return `${wk.end_session_endpoint}?${query}`
}

// found in cognito user pool -> federation -> identity providers
// for MPI Azure AD
const internalIdp = "mpi-azure-ad"

const externalIdpTest2 = "mpi-b2c-nonprod"
const externalIdpProduction = "mpi-b2c-prod"

export const internalIdProvider = (): string => {
  if (isDeploymentStage(stage)) {
    return internalIdp
  } else {
    throw new Error(`Unrecognised stage ${stage}`)
  }
}
export const externalIdProvider = (): string => {
  switch (deploymentStage()) {
    case DeploymentStages.TST2:
      return externalIdpTest2
    case DeploymentStages.PRD:
      return externalIdpProduction
    default:
      return internalIdp
  }
}
